<template>
	<v-app-bar app color="baColorDeepBlue" flat height="71">
		<v-spacer></v-spacer>
		<div class="my-auto ml-4">
			<v-btn @click="signOut" text>
				<best-athletes-logo/>
			</v-btn>
		</div>
		<v-spacer></v-spacer>
	</v-app-bar>
</template>

<script>
import BestAthletesLogo from '../ui/BestAthletesLogo'

export default {
	name: 'Navbar',
	props: {
		appType: {
			type: String,
			default: 'main'
		}
	},
	methods: {
		signOut(){
			this.$auth.logout();
		},
		toggleSidebar(){
			this.updateShowSideDrawer(!this.ssd)
		},
	},
	components: {
		BestAthletesLogo,
	},
}
</script>

<style scoped lang="scss">
	.blue-bar {
		background-color: $ba-color-deep-blue-text;
	}
</style>

