
import Vue from 'vue';
import Page from '../views/Page.vue';
import Navbar from '../components/navigation/Navbar.vue';
import Footer from '../components/navigation/Footer.vue';
export default Vue.extend({
	components: {
		Page,
		Navbar,
		Footer,
	},
});
